<template>
  <div class="Report">
    <div class="banner Title">举报中心</div>
    <div class="banner ReportHome">
      <div class="title">
        举报原因
        <span>(多选)</span>
      </div>
      <!--  -->
      <div class="flex-warp">
        <div class="felx-title">虚假信息</div>
        <div class="flex-list">
          <div class="flex-item" v-for="(item,index) in xjxx" :key="index">
            <div class="name">{{item.name}}</div>
            <div class="desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="flex-warp">
        <div class="felx-title">虚假交易</div>
        <div class="flex-list">
          <div class="flex-item" v-for="(item,index) in xjjy" :key="index">
            <div class="name">{{item.name}}</div>
            <div class="desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="flex-warp">
        <div class="felx-title">恶劣行为</div>
        <div class="flex-list">
          <div class="flex-item" v-for="(item,index) in elxw" :key="index">
            <div class="name">{{item.name}}</div>
            <div class="desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="flex-warp">
        <div class="felx-title">其他原因</div>
        <div class="flex-list">
          <div class="flex-item" v-for="(item,index) in qt" :key="index">
            <div class="name">{{item.name}}</div>
            <div class="desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
      <!-- 原因描述 -->
      <div class="desc-content">
        <div class="desc-title">原因描述</div>
        <div class="textarea">
          <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="30" show-word-limit :autosize="{ minRows: 6, maxRows: 4}"></el-input>
        </div>
      </div>
      <!-- 上传图片 -->
      <div class="upload">
        <div class="upload-title">上传图片凭证（0/3）</div>
        <div class="upload-img">
          <ImageUpload v-model="upload" :isShowTip="false" :limit="3" />
        </div>
      </div>
      <!-- 提交 -->
      <div class="Submit">提交</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      xjxx: [
        {
          name: "卖家存在虚假信息",
          desc: "详情页描述作假、背景作假",
        },
        {
          name: "卖家存在虚假库存",
          desc: "在出售中无法接单，接单后延期开始 服务",
        },
        {
          name: "卖家有刷单行为",
        },
      ],
      xjjy: [
        {
          name: "卖家以退款/打折/升级服务等 换好评",
        },
        {
          name: "卖家以差评要挟换取好评",
        },
        {
          name: "卖家存在外包服务行为",
        },
      ],
      elxw: [
        {
          name: "差评后被卖家骚扰/辱骂",
        },
        {
          name: "卖家涉及违法违规涉黄",
        },
        {
          name: "卖家存在骚扰行为",
        },
        {
          name: "卖家诈骗钱财或物品",
        },
        {
          name: "卖家泄露信息（个人信息或交 易信息",
        },
      ],
      qt: [
        {
          name: "其他原因",
        },
      ],
      textarea: "",
      upload: "",
    };
  },
};
</script>
<style lang="less" scoped>
.Report {
  background: #f7f9fa;
  padding-bottom: 30px;
  .Title {
    font-weight: bold;
    color: #000000;
    line-height: 69px;
    font-size: 32px;
    padding: 30px 0;
  }
  .ReportHome {
    background: #ffffff;
    padding: 30px;
    box-sizing: border-box;
    .title {
      font-weight: 400;
      color: #000000;
      line-height: 52px;
      font-size: 24px;
      span {
        font-weight: 400;
        color: #999999;
        line-height: 43px;
        font-size: 20px;
      }
    }
    .flex-warp {
      margin-top: 30px;
      .felx-title {
        font-weight: 400;
        color: #999999;
        line-height: 35px;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .flex-list {
        display: flex;
        flex-wrap: wrap;
        .flex-item {
          width: 238px;
          height: 91px;
          border-radius: 4px;
          border: 1px solid #e8e8e8;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-right: 20px;
          cursor: pointer;
          box-sizing: border-box;
          &:hover {
            background: #fff0f0;
            border: 1px solid #fc6969;
          }
          .name {
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            font-size: 14px;
            text-align: center;
            width: 80%;
            margin: 6px auto 0;
          }
          .desc {
            font-weight: 400;
            color: #999999;
            // line-height: 26px;
            font-size: 12px;
            width: 80%;
            margin: 6px auto 0;
            text-align: center;
          }
        }
      }
    }
    .desc-content {
      margin-top: 30px;
      .desc-title {
        font-weight: 400;
        color: #000000;
        line-height: 52px;
        padding-bottom: 10px;
        font-weight: 700;
      }
      .textarea {
        width: 676px;
      }
    }
    .upload {
      margin-top: 30px;
      .upload-title {
        font-weight: 400;
        color: #000000;
        line-height: 52px;
        font-size: 24px;
        padding-bottom: 10px;
      }
    }
    .Submit {
      width: 75px;
      height: 36px;
      background: #fc3737;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 19px;
      font-size: 16px;
      margin-top: 30px;
      cursor: pointer;
    }
  }
}
</style>